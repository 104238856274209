import React from 'react';

import hero_photo from '../../assets/images/LandscapeOptimized.jpg';

// @notus-pro/react
import HeaderImageTitleCenter from '@notus-pro/react/HeaderImageTitleCenter';

const props = {
	bgImage: hero_photo,
	title: 'Your Project Starts With Us',
	description: 'Full-Service Construction Management and General Contracting'
};

export default function Header() {
	return (
		<>
			<HeaderImageTitleCenter {...props} />
		</>
	);
}
