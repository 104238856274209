import React from 'react';

import Skew from '@notus-pro/react/Skew';

import Header from '../components/header/header.component';
import Navbar from '../components/navbar/navbar.component';
import Hero from '../components/hero/hero.component';
import Services from '../components/services/services.component';
// import Team from '../components/team/team.component';
import Contact from '../components/contact/contact.component';
import Footer from '../components/footer/footer.component';

import styled from 'styled-components';

const SkewCorrection = styled.div`
	transform: translateY(-70px);
`;

function Home() {
	// document.documentElement.classList.remove('nav-open');
	// React.useEffect(() => {
	// 	document.body.classList.add('index-page');
	// 	window.scrollTo(0, 0);
	// 	document.body.scrollTop = 0;
	// 	return function cleanup() {
	// 		document.body.classList.remove('index-page');
	// 	};
	// });
	return (
		<>
			<Navbar />
			<Header />
			<Hero />
			<SkewCorrection>
				<Skew color='white' />
			</SkewCorrection>
			<Services />
			{/* <Team /> */}
			<Contact />
			<Footer />
		</>
	);
}

export default Home;
