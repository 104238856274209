import React from 'react';

// @notus-pro/react
import NavbarLinks from '@notus-pro/react/NavbarLinks';

import logo from '../../assets/images/android-chrome-512x512.png';

const props = {
	color: 'transparent',
	type: 'absolute',
	logoImage: logo,
	logoText: "Floyd's General Contracting",
	logoLink: { to: '/' }
	// socials: [
	// 	{
	// 		icon: 'facebook',
	// 		text: 'Share on Facebook',
	// 		link: 'https://www.facebook.com/CreativeTim'
	// 	},
	// 	{
	// 		icon: 'instagram',
	// 		text: 'Follow on Instagram',
	// 		link: 'https://www.instagram.com/creativetimofficial/'
	// 	}
	// ]
	// rightLinks: [
	// 	{ href: '#about', children: 'About' },
	// 	{ href: '#services', children: 'Services' },
	// 	// { href: '#pablo', children: 'Team' },
	// 	{ href: '#contact', children: 'Contact' }
	// ]
};

export default function Navbar() {
	return (
		<>
			<NavbarLinks {...props} />
		</>
	);
}
