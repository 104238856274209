import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

// components
import Spinner from './components/spinner/spinner.component';
import ErrorBoundary from './components/error-boundary/error-boundary.component';

// views
import Home from './views/home.view';

import "@notus-pro/react/tailwind.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

ReactDOM.render(
    <>
			<ErrorBoundary>
				<Suspense fallback={<Spinner />}>
					<BrowserRouter>
						<Switch>
							<Route exact path='/' render={(props) => <Home {...props} />} />
						</Switch>
					</BrowserRouter>
				</Suspense>
			</ErrorBoundary>
    </>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
