import React from 'react';

import backhoe from '../../assets/images/Optimized-BackHoeServices.png';
import road from '../../assets/images/Optimized-RoadMaintenance.png';
import excavation from '../../assets/images/Optimized-Excavation.png';
import many_services from '../../assets/images/Optimized-ManyServices.png';

// @notus-pro/react
import MediaPlayerImageOnly from '@notus-pro/react/MediaPlayerImageOnly';
import Features3 from '@notus-pro/react/Features3';

const props1 = {
	defaultOpened: 0,
	size: 'regular',
	items: [backhoe, road, excavation, many_services]
};

const props = {
	heading3: {
		color: 'emerald',
		subtitle: 'Our Services',
		title: 'Experienced Professional Contracting',
		description: 'Helping your business at any stage of it’s production.',
		alignment: 'center'
	},
	infoAreas: [
		{
			icon: 'fas fa-helmet-safety',
			color: 'amber',
			title: 'Construction',
			// description:
			// 	'The kit comes with three pre-built pages to help you get started faster.',
			list: [
				{
					icon: 'fas fa-check',
					text: 'Heavy Construction Equipment & Supplies'
				},
				{
					icon: 'fas fa-check',
					text: 'Landscaping/Agricultural/Excavation'
				},
				{ icon: 'fas fa-check', text: 'Road Construction and Materials' }
			]
		},
		{
			icon: 'fas fa-comments',
			color: 'amber',
			title: 'Consulting',
			// description:
			// 	"If you're taught you can’t do anything, you won’t do anything.",
			list: [
				{ icon: 'fas fa-check', text: 'Architectural Design/Plans' },
				{ icon: 'fas fa-check', text: 'Zoning Analysis' },
				{ icon: 'fas fa-check', text: 'Transportation and Logistics' }
				// { icon: 'fas fa-check', text: 'Contact for more' }
			]
		}
	]
};

export default function Service() {
	return (
		<>
			<Features3 {...props} />

			<MediaPlayerImageOnly {...props1} />
			<div style={{ marginBottom: '5rem' }}></div>
		</>
	);
}
