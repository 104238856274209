import React from 'react';

// @notus-pro/react
import FooterSmall from '@notus-pro/react/FooterSmall';

const props = {
	copyright: {
		children: "Floyd's General Contracting",
		href: '/'
	},
	links: [
		{
			children: "Floyd's General Contracting",
			href: '/'
		}
		// {
		// 	children: 'About',
		// 	href: '#about'
		// },
		// {
		// 	children: 'Services',
		// 	href: '#services'
		// },
		// // {
		// // 	children: 'Team',
		// // 	href: '',
		// // 	target: '_blank'
		// // },
		// {
		// 	children: 'Contact',
		// 	href: '#contact'
		// }
	]
};

export default function Footer() {
	return (
		<>
			<FooterSmall {...props} />
		</>
	);
}
