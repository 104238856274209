import React from 'react';

export default function Contact() {
	return (
		<>
			<div className='pt-12 mb-12 pb-12 container mx-auto px-4'>
				<div className='mb-12 flex flex-wrap -mx-4 justify-center'>
					<div className='px-4 relative w-full lg:w-8/12 text-center'>
						<h6 className='mb-2 text-lg font-bold uppercase text-lightBlue-500'>
							Contact Us
						</h6>
						<h2 className='text-4xl font-bold mt-0 mb-1 text-blueGray-700'>
							Are you looking for answers?
						</h2>
						<p className='mt-2 mb-4 text-xl leading-relaxed text-blueGray-400'>
							Our team of certified contractors are ready to assist your
							business.
						</p>
					</div>
				</div>
				<div className='flex flex-wrap -mx-4 mt-12'>
					<div className='px-4 relative w-full lg:w-3/12 w-full md:w-6/12 w-6/12 border-r'>
						<div className='text-center py-6'>
							<div className='text-white bg-lightBlue-500 shadow-lg rounded rounded-full justify-center items-center inline-flex text-center p-2 mb-6 w-16 h-16'>
								<i className='fas fa-map-marker-alt text-lg'></i>
							</div>
							<h4 className='text-xl font-bold leading-tight mt-0 mb-2'>
								Address
							</h4>
							<div className='mb-4'>
								<p className='text-blueGray-500'>PO Box 6 Lutsel K'e, NT</p>
							</div>
						</div>
					</div>
					<div className='px-4 relative w-full lg:w-3/12 w-full md:w-6/12 w-6/12 border-r'>
						<div className='text-center py-6'>
							<div className='text-white bg-lightBlue-500 shadow-lg rounded rounded-full justify-center items-center inline-flex text-center p-2 mb-6 w-16 h-16'>
								<i className='fas fa-envelope text-lg'></i>
							</div>
							<h4 className='text-xl font-bold leading-tight mt-0 mb-2'>
								Email
							</h4>
							<div className='mb-4'>
								<p className='text-blueGray-500'>
									floyd.abel@floyds.contractors
								</p>
							</div>
						</div>
					</div>
					<div className='px-4 relative w-full lg:w-3/12 w-full md:w-6/12 w-6/12 border-r'>
						<div className='text-center py-6'>
							<div className='text-white bg-lightBlue-500 shadow-lg rounded rounded-full justify-center items-center inline-flex text-center p-2 mb-6 w-16 h-16'>
								<i className='fas fa-mobile-alt text-lg'></i>
							</div>
							<h4 className='text-xl font-bold leading-tight mt-0 mb-2'>
								Phone
							</h4>
							<div className='mb-4'>
								<p className='text-blueGray-500'>+1(867) 446 9163</p>
							</div>
						</div>
					</div>
					<div className='px-4 relative w-full lg:w-3/12 w-full md:w-6/12 w-6/12'>
						<div className='text-center py-6'>
							<div className='text-white bg-lightBlue-500 shadow-lg rounded rounded-full justify-center items-center inline-flex text-center p-2 mb-6 w-16 h-16'>
								<i className='fas fa-user-circle text-lg'></i>
							</div>
							<h4 className='text-xl font-bold leading-tight mt-0 mb-2'>
								Contact
							</h4>
							<div className='mb-4'>
								<p className='text-blueGray-500'>Floyd Abel</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
