import React from 'react';

// @notus-pro/react
import Hero1 from '@notus-pro/react/Hero1';

import lk from '../../assets/images/Lutselke-2.jpg';

const props = {
	heading3: {
		color: 'lightBlue',
		subtitle: 'on the east arm',
		title: 'Meeting Your Contracting Needs',
		description:
			'Our team works tirelessly to help you carry out ideas together, getting the job done!',
		alignment: 'center'
	},
	infoCards: [
		{
			icon: 'fa fa-handshake',
			iconColor: 'orange',
			title: 'Cooperation',
			description: 'We strive to work together'
		},
		{
			icon: 'fa fa-user-tie',
			iconColor: 'lightBlue',
			title: 'Verified Business',
			description:
				"Floyd's General Contracting is registered under the Business Incentive Policy of the Government of the Northwest Territories"
		},
		{
			icon: 'fas fa-feather-pointed',
			iconColor: 'emerald',
			title: 'Indigenous',
			description: "Based in Lutsel K'e, NT and locally owned"
		}
	],
	infoArea: {
		icon: 'fas fa-circle-info',
		iconColor: 'blueGray',
		title: "About Floyd's General Contracting",
		className: 'about',
		description: [
			'Our company was founded in 2013 on traditional Akaitcho land by an Ancestral Mandate with the sole intention to provide development and sustainability for our communities. It resinates from top to bottom and shows in our servicing.',
			'By investing and supporting local communities, we build infrastructure and leaders to inspire the future to create positive change.'
		],
		link: { text: '', link: '/', innerLink: true }
	},
	skewCard: {
		image: lk,
		title: 'Working for our Communities',
		description:
			'Our philosophy is to give back. We are supporting each other to reach our goals. ',
		color: 'lightBlue'
	}
};

export default function Hero() {
	return (
		<>
			<Hero1 {...props} />
		</>
	);
}
